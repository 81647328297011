var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog_drawer_wrapper",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.$api.stopPropagation($event)
        },
      },
    },
    [
      _c("div", { staticClass: "info_section" }, [
        _c("div", { staticClass: "group_nickname" }, [
          _c(
            "div",
            {
              staticClass:
                "title_group d-flex align-center justify-space-between",
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("工作组名称")]),
              _c("div", {
                staticClass: "qrcode cp",
                on: { click: _vm.openChatQRCode },
              }),
            ]
          ),
          _c("div", { staticClass: "input_group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.chat_name,
                  expression: "chat_name",
                },
              ],
              ref: "group_name",
              staticClass: "group_name",
              class: { on: _vm.group_name_focus },
              attrs: {
                type: "text",
                maxlength: _vm.chatname_max_length,
                tabindex: "-1",
              },
              domProps: { value: _vm.chat_name },
              on: {
                focus: function ($event) {
                  _vm.group_name_focus = true
                },
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.$refs.group_name.blur()
                },
                blur: function ($event) {
                  _vm.group_name_focus = false
                  _vm.dochangeChatName()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.chat_name = $event.target.value
                },
              },
            }),
          ]),
        ]),
        0
          ? _c("div", { staticClass: "group_nickname" }, [
              _c("div", { staticClass: "title" }, [_vm._v("我在群组内的昵称")]),
              _c("div", { staticClass: "input_group" }, [
                _vm._v("UTEC-张立(功能暂未开通)"),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "user_list_group" }, [
          _c(
            "div",
            { staticClass: "list_group" },
            [
              _c(
                "div",
                {
                  staticClass: "add cp",
                  on: { click: _vm.openAddContactToDialog },
                },
                [
                  _c("img", {
                    staticClass: "img",
                    attrs: {
                      src: require("@/assets/images/dialog/drawer_add.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "text" }, [_vm._v("添加")]),
                ]
              ),
              _vm.is_create_user
                ? _c(
                    "div",
                    {
                      staticClass: "add cp",
                      on: {
                        click: function ($event) {
                          return _vm.startRemove()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "img",
                        attrs: {
                          src: require("@/assets/images/dialog/drawer_remove.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [_vm._v("移除")]),
                    ]
                  )
                : _vm._e(),
              _vm.dialog_user_list && _vm.dialog_user_list.length
                ? _vm._l(_vm.dialog_user_list, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "unit" },
                      [
                        index < _vm.chatUserNumberLimit
                          ? _c("WorkTeamDetailUnit", {
                              attrs: {
                                width: "40px",
                                imgwidth: "40px",
                                imgheight: "40px",
                                fontsize: "12px",
                                placement: "left-start",
                                userData: _vm.UserInfo(item),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  })
                : [
                    _c("div", { staticClass: "load_user_list un_sel" }, [
                      _vm._v("加载中..."),
                    ]),
                  ],
            ],
            2
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMoreUser,
                  expression: "showMoreUser",
                },
              ],
              staticClass: "more_group",
            },
            [
              _c(
                "div",
                {
                  staticClass: "more cp",
                  on: {
                    click: function ($event) {
                      _vm.switchMoreUser = !_vm.switchMoreUser
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.switchMoreUser ? "收起" : "查看全部") +
                      "(" +
                      _vm._s(_vm.dialog_user_list.length) +
                      ")"
                  ),
                ]
              ),
              0 ? _c("div", { staticClass: "icon" }) : _vm._e(),
            ]
          ),
        ]),
        0
          ? _c("div", { staticClass: "fun_group" }, [_vm._m(0), _vm._m(1)])
          : _vm._e(),
        1
          ? _c("div", { staticClass: "group_nickname" }, [
              _c("div", { staticClass: "title" }, [_vm._v("我在群组内的昵称")]),
              _c("div", { staticClass: "input_group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chat_username,
                      expression: "chat_username",
                    },
                  ],
                  staticClass: "group_name",
                  class: { on: _vm.group_name_focus },
                  attrs: {
                    type: "text",
                    maxlength: _vm.chatname_max_length,
                    placeholder: "点击设置昵称",
                  },
                  domProps: { value: _vm.chat_username },
                  on: {
                    blur: function ($event) {
                      return _vm.changeChatUsername()
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.changeChatUsername()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.chat_username = $event.target.value
                    },
                  },
                }),
              ]),
            ])
          : _vm._e(),
        _vm.chat_detail.chat.courceTitle
          ? _c(
              "div",
              {
                staticClass:
                  "course_wrapper d-flex align-center justify-space-between",
              },
              [
                _c("div", { staticClass: "title flex-shrink-0" }, [
                  _vm._v("\n        课程\n      "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "course cp",
                    on: { click: _vm.viewCoursePackage },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.chat_detail.chat.courceTitle) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm.chat_detail
          ? _c("div", { staticClass: "switch_group" }, [
              0
                ? _c("div", { staticClass: "switch_line" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v("仅看与我有关的任务单"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "switch" },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#0054A6",
                            "inactive-color": "#DDDDDD",
                          },
                          model: {
                            value: _vm.onlyme,
                            callback: function ($$v) {
                              _vm.onlyme = $$v
                            },
                            expression: "onlyme",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              0
                ? _c("div", { staticClass: "switch_line" }, [
                    _c("div", { staticClass: "text" }, [_vm._v("消息免打扰")]),
                    _c(
                      "div",
                      { staticClass: "switch" },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#0054A6",
                            "inactive-color": "#DDDDDD",
                          },
                          model: {
                            value: _vm.dontDisturb,
                            callback: function ($$v) {
                              _vm.dontDisturb = $$v
                            },
                            expression: "dontDisturb",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "switch_line" }, [
                _c("div", { staticClass: "text" }, [_vm._v("消息免打扰")]),
                _c(
                  "div",
                  { staticClass: "switch" },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#0054A6",
                        "inactive-color": "#DDDDDD",
                        "active-value": 1,
                        "inactive-value": 0,
                      },
                      on: { change: _vm.chat_detail.changeDoNotDistrub },
                      model: {
                        value: _vm.chat_detail.isdisturb,
                        callback: function ($$v) {
                          _vm.$set(_vm.chat_detail, "isdisturb", $$v)
                        },
                        expression: "chat_detail.isdisturb",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "switch_line" }, [
                _c("div", { staticClass: "text" }, [_vm._v("置顶")]),
                _c(
                  "div",
                  { staticClass: "switch" },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#0054A6",
                        "inactive-color": "#DDDDDD",
                        "active-value": 1,
                        "inactive-value": 0,
                      },
                      on: { change: _vm.changeIstop },
                      model: {
                        value: _vm.chat_detail.istop,
                        callback: function ($$v) {
                          _vm.$set(_vm.chat_detail, "istop", $$v)
                        },
                        expression: "chat_detail.istop",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        0
          ? _c("div", { staticClass: "radio_group" }, [
              _c("div", { staticClass: "radio_line" }, [
                _c("div", { staticClass: "text" }, [
                  _vm._v("强制模板内容对所有组员可见"),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "radio_box",
                    class: { on: _vm.everybodyCanSee == true },
                    on: {
                      click: function ($event) {
                        return _vm.setDialogAuthority("everybodyCanSee")
                      },
                    },
                  },
                  [_c("div", { staticClass: "radio" })]
                ),
              ]),
              _c("div", { staticClass: "radio_line" }, [
                _c("div", { staticClass: "text" }, [
                  _vm._v("强制模板内容仅模板成员可见"),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "radio_box",
                    class: { on: _vm.memberCanSee == true },
                    on: {
                      click: function ($event) {
                        return _vm.setDialogAuthority("memberCanSee")
                      },
                    },
                  },
                  [_c("div", { staticClass: "radio" })]
                ),
              ]),
              _c("div", { staticClass: "radio_line" }, [
                _c("div", { staticClass: "text" }, [
                  _vm._v("允许成员自行设定"),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "radio_box",
                    class: { on: _vm.custom == true },
                    on: {
                      click: function ($event) {
                        return _vm.setDialogAuthority("custom")
                      },
                    },
                  },
                  [_c("div", { staticClass: "radio" })]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      1
        ? _c("div", { staticClass: "btn_section" }, [
            _vm.is_create_user
              ? _c(
                  "button",
                  {
                    staticClass: "dissolve btn btn_light button cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.touchMigrateTaskSelectTask.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v("\n      任务迁移\n    ")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "button btn_light",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.exitWorkingTeam()
                  },
                },
              },
              [_vm._v("\n      退出工作组\n    ")]
            ),
            _vm.is_create_user
              ? _c(
                  "button",
                  {
                    staticClass: "dissolve btn btn_light button cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.doDeleteChat.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n      解散工作组\n    ")]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fun publicity" }, [
      _c("img", {
        staticClass: "img",
        attrs: {
          src: require("@/assets/images/dialog/publicity.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "name" }, [_vm._v("信息公示")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fun free_form" }, [
      _c("img", {
        staticClass: "img",
        attrs: {
          src: require("@/assets/images/dialog/free_form.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "name" }, [_vm._v("自由表单")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }